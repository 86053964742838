/* Navbar.module.css */

.navbar {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(18, 18, 18);
  background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  height: 85px;
  font-family: "Consolas", monospace;
}

.navbarLogo a {
  height: 40px;
  text-decoration: none;
  color: #333;
}

.navbarLinks {
  display: flex;
}

.navbarLinks a {
  margin: 20px;
  text-decoration: none;
  color: white;
}

.navbarToggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.navbarToggleIcon {
  display: block;
  width: 25px;
  height: 2px;
  margin-bottom: 4px;
  background-color: #333;
  transition: transform 0.3s ease-in-out;
}

.fontGreen {
  color: green;
}

.open {
  transform: rotate(45deg);
}

.navbarLinks.show {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 10px;
}

.logoText {
  color: white;
  font-size: 50px;
}

/* Mobile menu styles */
@media screen and (max-width: 600px) {
  .navbarLogo {
    width: 100%;
    text-align: center;
  }

  .navbarLinks {
    display: none;
  }

  .navbarToggle {
    display: block;
  }

  .navbar {
    height: 50px;
  }

  .logoText {
    font-size: 30px;
  }

}
