.iconSize {
  width: 128px;
  height: 128px;
  margin: 1em;
}

.cardSize {
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 2em;
}

.skillTitle {
  font-family: "Consolas", monospace;
  font-size: 2em;
  margin: 0;
}

.skillsHeader {
  font-size: 100px;
  font-family: "Consolas", monospace;
  margin-top: 2em;
}

.skillCategory {
  font-size: 50px;
  font-family: "Consolas", monospace;
}

.fontGreen {
  color: green;
}

.standOutFont {
  font-size: 30px;
}

.skillsContainer {
  display: flex;
  justify-content: center;
}

.skillsDivider {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .iconSize {
    width: 64px;
    height: 64px;
    margin: 1em;
  }

  .skillTitle {
    font-family: "Consolas", monospace;
    font-size: 1em;
    margin: 0;
  }
  
  .cardSize {
    width: 100px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 2em;
  }
}

@media only screen and (max-width: 1350px) {

  .skillsHeader {
    margin-top: 1.5em;
    font-size: 50px;
    font-family: "Consolas", monospace;
  }

  .aboutDescription {
    padding: 2em;
  }

  .standOutFont {
    font-size: 25px;
  }

  .skillCategory {
    font-size: 35px;
  }

  .skillsContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

}